import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import HeroCurved, {
  HeroWithSideImageData,
} from 'components/blocks/HeroBanner/HeroCurved';
import mapBlocks from 'helpers/blockMappers/mapBlocks';
import { CsBlock } from 'helpers/blockMappers/mappingUtils';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { PageMeta } from 'types/contentStack';

type Props = {
  data: TemplateIPageData;
};

const TemplateIPage: React.FC<Props> = ({ data }) => {
  const page = data.csTemplateI;
  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);

  return (
    <Layout
      url={page.url}
      pageMetadata={meta}
      breadcrumbs={breadcrumbs}
      locale={page.locale}>
      <HeroCurved hero_with_side_image={page.hero_with_side_image} />
      {page.blocks &&
        mapBlocks(page.blocks, { pageMeta: page.page_meta, pageUrl: page.url })}
    </Layout>
  );
};

export default TemplateIPage;

type TemplateIPageData = {
  csTemplateI: {
    title: string;
    url: string;
    locale: string;
    hero_with_side_image: HeroWithSideImageData;
    blocks: CsBlock[];
    page_meta: PageMeta;
  };
};

export const query = graphql`
  query($id: String!) {
    csTemplateI(id: { eq: $id }) {
      title
      url
      locale
      hero_with_side_image {
        ...HeroWithSideImage
      }
      blocks {
        accordion {
          ...AccordionBlockTemplateIPage
        }
        action_cards {
          ...ActionCardsBlock
        }
        cta_banners {
          ...CtaBannersBlock
        }
        contact_cards {
          ...ContactCardsBlock
        }
        content_tabs {
          ...ContentTabsBlock
        }
        detailed_related_content {
          ...DetailedRelatedContentBlock
        }
        document_download_link_list_with_pagination {
          ...DocumentDownloadLinkListWithPaginationBlock
        }
        feature {
          ...FeatureBlock
        }
        feature_video {
          ...FeatureVideoBlock
        }
        image_break {
          ...ImageBreakBlock
        }
        image_and_cta_cards {
          ...ImageAndCtaCardsBlock
        }
        info_cards {
          ...InfoCardsBlock
        }
        mini_guide {
          ...MiniGuideBlock
        }
        product_cards {
          ...ProductCardsBlock
        }
        related_content_card_list_with_pagination {
          ...RelatedContentCardListWithPaginationBlock
        }
        rich_text {
          ...RichTextBlock
        }
        social_media_share {
          ...ShareWidgetBlock
        }
        soundcloud_player {
          ...SoundCloudPlayerBlock
        }
        split_info_card {
          ...SplitInfoCardBlock
        }
        table {
          ...TableBlock
        }
        testimonial {
          ...TestimonialBlock
        }
        testimonial_with_image {
          ...TestimonialWithImageBlock
        }
      }
      page_meta {
        ...PageMeta
      }
    }
  }
`;
